import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  mobileThresholdPixels, colors, margins, ContainerBlock,
}
  from '../home/v2/styledComponents';
import { formatNameForURL } from '../../services/seoLocalitiesFormatter';

const StyledContainerBlock = styled(ContainerBlock)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0px ${margins.m} ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.m};
    padding: 0px;
    width: 100%;
  }
`;

const LinkContainer = styled.span`
  min-width: 125px;
  margin: 0px ${margins.s};
`;

const Span = styled.span`
  font-size: 10px;
  color: ${colors.darkGrey};
  margin: 0px auto;
`;

function getExtraMainCitiesLinks() {
  const links = [];
  const localities = [
    'Aix-En-Provence',
    'Bordeaux',
    'Lille',
    'Nice',
    'Rennes',
    'Strasbourg',
    'Toulouse',
  ];
  localities.forEach((cityIt) => {
    links.push({
      to: `/retouche-vetement/${cityIt.toLowerCase()}/`,
      anchor: `Retouche vêtement ${cityIt}`,
    });
  });
  return links;
}

function getParisLinks({ locality, arrondissement }) {
  const links = [];
  if (!locality) {
    const arrondissementsArray = Array.from(Array(20).keys()).filter((arrondissementIt) => `${arrondissementIt + 1}` !== arrondissement);
    arrondissementsArray.forEach((index) => {
      const arrondissementIt = index + 1;
      links.push({
        to: `/retouche-vetement/paris/paris-${`0${arrondissementIt}`.slice(-2)}/`,
        anchor: `Retouche vêtement Paris ${arrondissementIt}`,
      });
    });
  }
  const localitiesArray = ['Clichy', 'Neuilly-Sur-Seine', 'Levallois-Perret', 'Boulogne-Billancourt', 'Issy-les-Moulineaux', 'Montreuil',
    'Asnières-sur-Seine', 'Antony', 'Belleville', 'Bois Colombes', 'Courbevoie', 'Colombes', 'Clamart', 'Champs-Elysées',
    'Les Halles', 'Nanterre', 'Saint-Maur-des-Fossés', 'Rueil-Malmaison', 'Rochechouart', 'Vitry-sur-Seine', 'Villejuif', 'Versailles'];
  localitiesArray.filter((localityIt) => localityIt !== locality).forEach((localityIt) => {
    links.push({
      to: `/retouche-vetement/paris/${formatNameForURL(localityIt)}/`,
      anchor: `Retouche vêtement ${localityIt}`,
    });
  });
  return (arrondissement || locality) ? links : links.concat(getExtraMainCitiesLinks());
}

function getMarseilleLinks({ arrondissement }) {
  const links = [];
  const arrondissementsArray = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter((arrondissementIt) => `${arrondissementIt + 1}` !== arrondissement);
  arrondissementsArray.forEach((arrondissementIt) => {
    links.push({
      to: `/retouche-vetement/marseille/marseille-${`0${arrondissementIt}`.slice(-2)}/`,
      anchor: `Retouche vêtement Marseille ${arrondissementIt}`,
    });
  });
  return arrondissement ? links : links.concat(getExtraMainCitiesLinks());
}

function getLyonLinks({ locality, arrondissement }) {
  const links = [];
  const arrondissementsArray = Array.from(Array(9).keys()).filter((arrondissementIt) => `${arrondissementIt + 1}` !== arrondissement);
  arrondissementsArray.forEach((index) => {
    const arrondissementIt = index + 1;
    links.push({
      to: `/retouche-vetement/lyon/lyon-${`0${arrondissementIt}`.slice(-2)}/`,
      anchor: `Retouche vêtement Lyon ${arrondissementIt}`,
    });
  });
  if (!locality) {
    links.push({
      to: '/retouche-vetement/lyon/villeurbane/',
      anchor: 'Retouche vêtement Villeurbane',
    });
  }
  return (arrondissement || locality) ? links : links.concat(getExtraMainCitiesLinks());
}

function getOtherCityLinks({ city }) {
  const links = [];
  const localities = [
    'Paris',
    'Lyon',
    'Marseille',
    'Aix-En-Provence',
    'Bordeaux',
    'Caen',
    'Cannes',
    'Grenoble',
    'Lille',
    'Metz',
    'Montpellier',
    'Nantes',
    'Nice',
    'Nimes',
    'Rennes',
    'Rouen',
    'Strasbourg',
    'Toulouse',
    'Tours',
  ];
  localities.filter((cityIt) => cityIt !== city).forEach((cityIt) => {
    links.push({
      to: `/retouche-vetement/${cityIt.toLowerCase()}/`,
      anchor: `Retouche vêtement ${cityIt}`,
    });
  });
  return links;
}

function getLinks({ city, locality, arrondissement }) {
  if (city === 'Paris') { return getParisLinks({ locality, arrondissement }); }
  if (city === 'Marseille') { return getMarseilleLinks({ locality, arrondissement }); }
  if (city === 'Lyon') { return getLyonLinks({ locality, arrondissement }); }
  return getOtherCityLinks({ city });
}

class FooterSEOLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { links: getLinks(props) };
  }

  render() {
    const { links } = this.state;
    return (
      <StyledContainerBlock>
        {links.map((link) => (
          <LinkContainer key={link.to}>
            <Link
              to={link.to}
              style={{ textDecoration: 'none' }}
            >
              <Span>{link.anchor}</Span>
            </Link>
          </LinkContainer>
        ))}
      </StyledContainerBlock>
    );
  }
}

FooterSEOLinks.propTypes = {
  city: PropTypes.string.isRequired,
  locality: PropTypes.string,
  arrondissement: PropTypes.string,
};

FooterSEOLinks.defaultProps = {
  locality: null,
  arrondissement: null,
};

export default FooterSEOLinks;
