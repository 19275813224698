import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Container, Content } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import ReferrerBanner from '../components/home/ReferrerBanner';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import FootNote from '../components/LandingPage/FootNote';
import FooterSEOLinks from '../components/LandingPage/FooterSEOLinks';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';

import {
  getFootNoteTitle, getBreadCrumb, getPosition, getCouturierAltTag, getAlterationAltTagLocality,
} from './LandingLocalityFormatter';

class LandingLocalityPageTemplate extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { data: { landingsLocalitiesJson }, referrer } = this.props;
    const {
      slug, city, locality, arrondissement, paragraph, links,
    } = landingsLocalitiesJson;
    const preFilledSteps = { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' };
    return (
      <Layout routeSlug={slug}>
        <Container>
          <Content full hasNoBanner>
            {referrer && <ReferrerBanner referrer={referrer} />}
            <MenuBar />
            <Header
              title={`Vos retouches de vêtements à domicile - ${getPosition({ locality, city, arrondissement })}`}
              hideNote
              smallTitle
              titleLeft
              preFilledSteps={preFilledSteps}
            />
            <BenefitsBanner altTag={getAlterationAltTagLocality({ locality, city, arrondissement })} />
            <ACouturierAtHome
              preFilledSteps={preFilledSteps}
              couturierAltTag={getCouturierAltTag({ locality, city, arrondissement })}
            />
            <FeedbacksBannerAds />
            <FAQAds preFilledSteps={preFilledSteps} city={city} isSEO />
            <PartnersPager />
            <FootNote
              title={getFootNoteTitle({ locality, city, arrondissement })}
              paragraph={paragraph}
              links={links}
              itemListElement={getBreadCrumb({ locality, city, arrondissement })}
            />
            <FooterSEOLinks city={city} locality={locality} arrondissement={arrondissement} />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

LandingLocalityPageTemplate.propTypes = {
  data: PropTypes.shape({
    landingsLocalitiesJson: PropTypes.shape({
      slug: PropTypes.string,
      city: PropTypes.string,
      locality: PropTypes.string,
      arrondissement: PropTypes.string,
      paragraph: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  referrer: PropTypes.shape({}),
};

LandingLocalityPageTemplate.defaultProps = {
  referrer: null,
};

export default LandingLocalityPageTemplate;

export const landingLocalityPageQuery = graphql`
query landingsLocalitiesQuery($slug: String!) {
  landingsLocalitiesJson(slug: { eq: $slug }) {
    slug
    city
    locality
    zipcode
    arrondissement
    paragraph
    links
  }
}`;
